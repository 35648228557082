<template>
  <li class="unsubscribe-buttons">
    <button v-if="!noTitle" @click.stop="modalOpenPreview">{{ talkRoom.name }}</button>
    <button v-else @click.stop="modalOpenPreview">退会</button>

    <modal :name="`unsubscribe-${talkRoom.id}`" :adaptive="true"
           height="auto" width="95%" :maxWidth="600" :styles="{borderRadius:'10px'}">
      <div class="modal-window">
        <div class="modal-content">
          <div class="txt_12">
            <div class="font-bold">{{ talkRoom.name }}<br>
            を退会します。
            </div>
            <div class="text-red">※ファン歴がリセットされます。</div>
          </div>
          <div class="bt_yes_no">
            <div class="bt_delete"><a @click.stop="beforeUnsubscribe">退会</a></div>
            <div class="bt_cancel"><a @click.stop="$modal.hide(`unsubscribe-${talkRoom.id}`)">キャンセル</a></div>
          </div>
        </div>
      </div>
    </modal>
    <modal :name="`unsubscribe-${talkRoom.id}-2`" :adaptive="true"
           height="auto">
      <div class="modal-window">
        <div class="modal-content">
          <div class="txt_12">
            あなたは{{ position }}番目の会員です。<br>
            退会をすると全てのデータが削除され、削除されたアカウントは復帰できません。<br>
            本当に退会しますか？
          </div>
          <div class="bt_yes_no">
            <div class="bt_delete"><a @click.stop="unsubscribe">退会</a></div>
            <div class="bt_cancel"><a @click.stop="$modal.hide(`unsubscribe-${talkRoom.id}-2`)">キャンセル</a></div>
          </div>
        </div>
      </div>
    </modal>
  </li>
</template>

<script>
import axios from 'axios';

export default {
  name: 'MyPageTalkRoomUnsubscribe',
  props: {
    talkRoom: Object,
    noTitle: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  computed: {
    loginUser () {
      return this.$store.state.loginUser;
    }
  },
  data () {
    return {
      position: null,
    }
  },
  created() {
  },
  methods: {
    modalOpenPreview () {
      this.$modal.show(`unsubscribe-${this.talkRoom.id}`);
    },
    async beforeUnsubscribe() {
      const response = await this.$http.get(`talk_room/${this.talkRoom.id}/position`);
      this.position = response.data.position;
      this.$modal.hide(`unsubscribe-${this.talkRoom.id}`);
      this.$modal.show(`unsubscribe-${this.talkRoom.id}-2`)
    },
    async unsubscribe () {
      const response = await this.$http.post(`talk_room/${this.talkRoom.id}/unsubscribe`);

      // トークルームのメッセージからユーザーIDを削除
      await axios.post(
          `https://${process.env.VUE_APP_CHAT_SERVER}/talk_rooms/${this.talkRoom.id}/user/${this.loginUser.id}/unsubscription`
      );

      this.$store.commit('setLoginUser', response.data);
      this.$modal.hide(`unsubscribe-${this.talkRoom.id}`);
      this.$modal.hide(`modal-unsubscribe`);
      this.$toasted.show('プラン退会しました', {position: 'top-center', duration: 2000});
    }
  }
}
</script>

<style lang="scss" scoped>
li.unsubscribe-buttons {
  margin: 5rem 5rem;
}
.font-bold {
  font-weight: bold;
}
.text-red {
  color: red;
  margin-top: 2px;
}
</style>
