<template>
  <div>
  <footer :class="{foot_fixed: fixed, foot_yellow: theme==='white'}">
    <nav>
      <ul class="page_foot">
        
        <li v-if="isArtist"><router-link :to="{name:'master-faq'}">マスター向けQ&A</router-link></li>
        <li v-else><router-link :to="{name:'faq'}">FAQ</router-link></li>
        <li><router-link :to="{name:'rule'}">利用規約</router-link></li>
        <li><router-link :to="{name:'privacy'}">プライバシー・ポリシー</router-link></li>
        <li><router-link :to="{name:'law'}">特定商取引に基づく表記</router-link></li>
        <li><a @click="$modal.show('modal-unsubscribe')">退会</a></li>
      </ul>
    </nav>
    <div class="copyright">©2021 すてきなせかい</div>
  </footer>
    <modal
        name="modal-unsubscribe" :adaptive="true"
        height="auto" width="95%" :maxWidth="600" :styles="{borderRadius:'10px'}">
      <div class="modal-window">
        <div class="modal-content ta_c unsubscribe-button">
          <div class="txt_12">
            退会するプランを選んでください。
          </div>
          <ul v-if="$store.state.loginUser">
            <MypageTalkRoomUnsubscribe
                v-for="talkRoom in $store.state.loginUser.talk_rooms"
                :key="`talk_room_${talkRoom.id}`"
                :talkRoom="talkRoom"
            ></MypageTalkRoomUnsubscribe>
          </ul>
          <div class="bt_cancel mt_15"><a @click="$modal.hide('modal-unsubscribe')">キャンセル</a></div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import MypageTalkRoomUnsubscribe from '@/components/MypageTalkRoomUnsubscribe';
export default {
    name: 'AppFooter',
  components: {MypageTalkRoomUnsubscribe},
  computed: {
    loginUser () {
      return this.$store.state.loginUser;
    },
    isArtist () {
      return !!this.loginUser.artist_id;
    }
  },
  props: {
    theme: {
      type: String,
      default: 'green'
    },
    fixed: {
      type: Boolean,
      default: false
    },
  }
}
</script>
